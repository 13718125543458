<template>
  <div id="app" class="container">
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />
    <b-btn @click="selectFile" size="lg" block><span v-if="!imageSrc">Wybierz</span><span v-else>Zmień</span> plik do edycji</b-btn>
    <div class="row mt-4" v-if="imageSrc">
      <div class="col-12 col-lg-9">
      <div class="card">
        <div class="card-header">Edycja obrazu</div>
        <div class="card-body p-0">
          <vue-cropper
            v-if="imageSrc"
            @ready="onReady"
            ref="cropper"
            :src="imageSrc"
            alt="Source Image"></vue-cropper>
        </div>
      </div>
    </div>
      <div class="col">
        <div class="card mt-4 mt-lg-0">
          <div class="card-header">Opcje</div>
          <div class="card-body">
            <b-form-group label="Rozmiar kadrowanego pola">
              <b-form-radio-group
                v-model="selectedType"
                @input="changeCropperAspect"
                :options="options"
                stacked
                buttons
              ></b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <div class="card mt-4">
          <div class="card-body">
            <a ref="downloadLink" class="btn btn-primary" id="download" :href="downloadHref" download="cropped.jpg" style="display:none;">Pobierz</a>
            <b-btn size="lg" variant="primary" block @click="download">Pobierz zdjęcie</b-btn></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
  name: 'App',
  components: { VueCropper},
  data: () => ({
    imageSrc: null,
    file: null,
    fileType: null,
    selectedType: null,
    options: [
      { text: 'Portal [nowy] (1200x675)', value: 'portal-new' },
      { text: 'Portal [stary] (739x320)', value: 'portal' },
      { text: 'Wieści Rolnicze (800x395)', value: 'wr' },
      { text: 'FB Kwadrat (900x900)', value: 'fb-square' },
      { text: 'FB Pion (640x960)', value: 'fb-vertical' },
      { text: 'FB Poziom (1200x900)', value: 'fb-horizontal' },
      { text: 'Videosfera Slider', value: 'vs-slider' },
      { text: 'Videosfera Kafel', value: 'vs-tile' }
    ],
    ratios: {
      'portal-new': {w: 1200, h: 675},
      'portal': {w: 739, h: 320},
      'wr': {w: 800, h: 395},
      'fb-square': {w: 900, h: 900},
      'fb-vertical': {w: 640, h: 960},
      'fb-horizontal': {w: 1200, h: 900},
      'vs-slider': {w: 1920, h: 825},
      'vs-tile': {w: 700, h: 480},
    },
    downloadHref: 'javascript:void(0);'
  }),
  mounted() {
  },
  methods: {
    selectFile() {
      this.$refs.input.click()
    },
    changeCropperAspect(checked) {
      const data = this.$refs.cropper.getData()
      const ratio = this.ratios[checked].w / this.ratios[checked].h
      this.$refs.cropper.setAspectRatio(ratio)
      this.$refs.cropper.setCropBoxData({
        x: 0,
        y: 0,
        width: data.width,
        height: data.height
      });

    },
    setupImage() {
      this.$refs.cropper.clear()
      this.imageSrc = URL.createObjectURL(this.file)
      this.fileType = this.file.type
      this.selectedType = 'portal-new'
      this.changeCropperAspect('portal-new')
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    onReady() {
      this.selectedType = 'portal-new'
      this.changeCropperAspect('portal-new')
    },
    download() {
      let canvas = this.$refs.cropper.getCroppedCanvas({width: this.ratios[this.selectedType].w, height: this.ratios[this.selectedType].h})
      // canvas.width = this.ratios[this.selectedType].w
      // canvas.height = this.ratios[this.selectedType].h
      this.downloadHref = canvas.toDataURL(this.fileType)
      const self = this
      this.$nextTick(() => {
        self.$refs.downloadLink.click()
      })
    },
  }
}
</script>

<style>

input[type="file"] {
  display: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
